import React from 'react';

import Layout from '../components/logic/layouts/Layout';
import Section from '../components/logic/layouts/Section';
import StyledH1 from '../components/styled/elements/StyledH1';
import StyledParagraph from '../components/styled/elements/StyledParagraph';

const NotFoundPage = () => (
  <Layout>
    <Section>
      <StyledH1>Sidan hittades inte</StyledH1>
      <StyledParagraph>Sidan du letar efter finns inte eller har tagits bort.</StyledParagraph>
    </Section>
  </Layout>
);

export default NotFoundPage;
